<template>
    <div class="bg-white box-shadow border-r px-20 pb-20" v-loading="loading">
        <div class="d-flex-center border-bottom">
            <h4 class="mr-auto color-333">{{ $t('account.options_orders') }}</h4>
            <el-button type="primary" size="mini" icon="el-icon-refresh" plain @click="onPage()">{{ $t('refresh') }}</el-button>
        </div>
        <el-row :gutter="10" type="flex" justify="center" align="middle" class="py-15 border-bottom font-12">
            <el-col :span='3'>{{ $t('order_no') }}</el-col>
            <el-col :span='3'>{{ $t('long') }}/{{ $t('short') }}</el-col>
            <el-col :span='3'>{{ $t('market.symbol') }}</el-col>
            <el-col :span='4'>{{ $t('options.ocp') }}</el-col>
            <el-col :span='2'>{{ $t('amount') }}</el-col>
            <el-col :span='3'>{{ $t('options.dt') }}/{{ $t('profit') }}</el-col>
            <el-col :span='2'>{{ $t('status') }}</el-col>
            <el-col class="text-right" :span='4'>{{ $t('options.oct') }}</el-col>
        </el-row>
        <div v-if="list.data.length">
            <el-row v-for="(item, index) in list.data" :key="index" :gutter="10" type="flex" justify="center" align="middle" class="py-15 border-bottom font-14">
                <el-col :span='3' class="font-12">{{ item.order_id }}</el-col>
                <el-col :span='3'>
                    <el-tag v-if="item.type" type="danger" effect="plain" hit>{{ $t('options.short') }}</el-tag>
                    <el-tag v-else type="success" effect="plain" hit>{{ $t('options.long') }}</el-tag>
                </el-col>
                <el-col :span='3'>{{ item.pair }} / {{ item.coin }}</el-col>
                <el-col :span='4'>
                    <div class="font-12">{{ item.price }} / {{ item.end_price }}</div>
                </el-col>
                <el-col :span='2'>{{ item.amount }}</el-col>
                <el-col :span='3'>{{ item.due_sec }}s / {{ item.profit_rate }}%</el-col>
                <el-col :span='2'>
                    <el-tag v-if="item.status === 0" type="primary" effect="plain" hit>Waiting</el-tag>
                    <el-tag v-else-if="item.status === 1" type="success" effect="plain" hit>+{{ item.profit }}</el-tag>
                    <el-tag v-else-if="item.status === 2" type="warning" effect="plain" hit>Draw</el-tag>
                    <el-tag v-else-if="item.status === 3" type="danger" effect="plain" hit>-{{ item.amount }}</el-tag>
                </el-col>
                <el-col class="text-right" :span='4'>
                    <div class="font-12">{{ item.create_time }}</div>
                    <div class="font-12">{{ item.end_time }}</div>
                </el-col>
            </el-row>
        </div>
        <el-empty v-else :image-size="100" description="No Data"></el-empty>
        <div class="d-flex-center pt-20">
            <el-pagination
                :hide-on-single-page="true"
                background
                layout="prev, pager, next"
                :page-size="list.per_page"
                :current-page="list.current_page"
                :total="list.total"
                @current-change="onPage"
            />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            query: { ...this.$route.query },
            list: {
                per_page: 1,
                last_page: 1,
                current_page: 1,
                total: 0,
                data: []
            },
            status: [
                ['primary', 'Waiting'],
                ['success', 'Win'],
                ['info', 'Draw'],
                ['danger', 'Fail']
            ]
        }
    },
    created: function() {
        this.fatchRecord()
    },
    methods: {
        fatchRecord: async function () {
            this.loading = true
            try {
                const { data } = await this.$request.post('options/order_pc', this.query)
                this.list = data
            } catch (error) {
                //
            }
            this.loading = false
            
        },
        onSearch() {
            this.fatchRecord()
            this.$router.replace({
                query: this.query
            })
        },
        onPage(page) {
            this.query.page = page || 1
            this.onSearch()
        }
    }
}
</script>
<style lang="scss" scoped>
//
</style>